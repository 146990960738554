<template>
  <div class="n-header">
    <div class="container">
      <!-- 左侧 -->
      <div class="n-l">
        <!-- logo -->
        <div class="n-logo">
          <!-- <img src="/imgs/logo.png" alt="" />
           -->
           北京罗麦科技有限公司
        </div>
        <!-- 导航 -->
        <div class="n-list">
          <a href="/">网站首页</a>
          <a href="/#/ac1">收费资讯</a>
          <a href="/#/info">关于我们</a>
          <!-- <a href="/#/xy">用户协议</a> -->
        </div>
      </div>

      <!-- 联系方式 -->
      <div class="n-phone">
        <!-- <span>网站网址：</span>
        <span>bjyxiakjicp.cn</span> -->
      </div>

      <!-- 登录注册 -->
      <div class="n-login">
        <span class="n-login" @click="goLogin()" v-show="this.LoggedShow">
          <!-- <i class="iconfont icon-denglu"></i> -->
          登录
        </span>
        <i>|</i>
        <span class="n-login" @click="goReg()" v-show="this.LoggedShow">
          <!-- <i class="iconfont icon-zhuce"></i> -->
          注册
        </span>
        <!-- 用户名 -->
        <span
          class="n-info"
          v-show="!this.LoggedShow"
          v-text="this.nv_user.Username"
          ><i class="iconfont icon-denglu"></i
        ></span>

        <!-- 退出登录 -->
        <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i>
          退出登录
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },

    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goReg() {
      this.$router.push("/register");
    },

    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      location.reload();
      // this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.n-header {
  width: 100%;
  height: 80px;
  background: #fff;
  border-bottom: 2px dashed #ccc;
  .container {
    height: 100%;
    // background: pink;
    @include flex();
    // 左侧
    .n-l {
      display: flex;
      align-items: center;
      height: 100%;
      // logo
      .n-logo {
        width: 260px;
        height: 100%;
        line-height: 80px;
        font-size: 16px;
        img {
          // width: 100%;
          height: 100%;
        }
      }

      //   导航
      .n-list {
        margin-left: 30px;
        a {
          font-size: 16px;
          margin: 0 15px;
          color: #666;
          transition: all 0.2s;
          font-weight: 500;
          &:hover {
            color: $colorZ;
          }
        }
      }
    }

    // 联系方式
    .n-phone {
      span {
        font-size: 18px;
        color: #ff6600;
        &:nth-child(1) {
          font-size: 16px;
        }
      }
    }

    // 登录注册
    .n-login {
      span {
        font-size: 14px;
        cursor: pointer;
        color: #666;
        transition: all 0.2s;
        &:hover {
          color: $colorZ;
        }
      }
      i {
        font-size: 16px;
        margin: 0 8px;
      }
    }
  }
}
</style>