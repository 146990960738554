<template>
  <div class="n-footer">
    <div class="container">
      <!-- logo -->
      <div class="f-logo">
        <!-- <img src="" alt="" /> -->
        <h2>北京罗麦科技有限公司</h2>
      </div>

      <p>
        Beijing Luomai Technology Co., Ltd
      </p>
      <p>公司传真：61669280-8008<span style="margin:0 15px;">--</span>域名：rolmex.com</p>

      <p>公司地址：北京市怀柔区雁栖大街16号</p>
      <p><a href="https://beian.miit.gov.cn/#/Integrated/index">备案号：京ICP备09110437号-4</a></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.n-footer {
  width: 100%;
  // height: 200px;
  background-color: #2d3237;
  .container {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    box-sizing: border-box;
    // logo
    .f-logo {
      width: 300px;
      height: 80px;
      margin: 0 auto;
      // img {
      //   width: 100%;
      //   height: 100%;
      // }
      h2 {
        color: #fff;
        text-align: center;
        line-height: 80px;
      }
    }

    p {
      font-size: 14px;
      color: #888;
      text-align: center;
      margin: 15px 0;
      a {
        color: #888;
      }
    }
  }
}
</style>